import * as React from 'react';
import { StoreContext } from '../../store/StoreProvider';
import { ActivationComponent } from './Activation';
import { HomeComponent } from './Home';
import { ContactusComponent } from './Contactus';
import { RewardComponent } from './Reward';
import './Home.css'
import { WarrantyComponent } from './Warranty';
import { SaleComponent } from './Sale';
import { HomeManagementComponent } from './HomeManagement';

interface HomeProps { }

export const Home = (props: HomeProps) => {

  const [store, dispatch] = React.useContext(StoreContext)

  const RenderScene = () => {
    switch (store.active_pane) {
      case 0:
        if (store?.userAppCorp && !store?.managementType) {
          return <HomeManagementComponent />;
        }
        return <HomeComponent />;
        break;
      case 1:
        return <WarrantyComponent />;
      case 2:
        return <ActivationComponent />;
        break;
      case 3:
        if( (store?.user?.Country === "PE" || store?.user?.Country === "NAC") ){
          return <ContactusComponent />;
        }
        return <HomeComponent />;
        break;
      case 4:
        return <RewardComponent />;
      case 5:
        return <SaleComponent />;
      default:

        return <HomeComponent />;
        break;
    }
  }


  return <RenderScene />
};
