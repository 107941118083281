import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import moment from "moment";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import AppConfig from "../../constants/AppConfig";
import { GetWarrantyRequest, CorpWarrantyRequestById } from "../../store/sagas/Warranty";
import { StoreContext } from "../../store/StoreProvider";
import { types } from "../../store/Warranty";
import Loading from "../Loading";
import { Alert } from "../Alert";
import { getI18N } from '../../i18n';

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import { Button } from "@material-ui/core";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const {
  functions: { setColorWarrantyState },
  colors: { blueblack }
} = AppConfig;

interface SaleListItemProps { }

export const SaleListItem = (props: any) => {
  const [store, dispatch] = React.useContext(StoreContext);
  const [loading, setLoad] = React.useState(false);
  const AlertRef = React.useRef<any>();

  const { Country } = store?.user;
  const currentLocale: string = Country || "PE";
  const i18n = getI18N({ currentLocale })

  const setDetail = () => {

    let idMiniCod = !!props?.IdMiniCod ? props?.IdMiniCod : `D${props?.DocEntry}`
    setLoad(true);
    let obj = {
      idTarjeta: props?.IdTarjeta,
      idMiniCod: idMiniCod,
      cardCode: store?.selected_client.Id,
    };

    const objCorp = {
      IdSolicitudGarantia: props?.IdSolicitud
    }

    CorpWarrantyRequestById(objCorp).then((response) => {
      if (response?.statusCode !== 200) {
        setLoad(false);
        AlertRef?.current?.open(response.message ?? "No se encontraron resultados", 'error')
        // alert(response?.message ?? "No se encontraron registros");
      }

      if (response?.statusCode === 200) {
        dispatch({ type: types.setWarrantyDetail, payload: response?.value });
        setLoad(false);
        props?.callback();

      }
    })
    return;
  };

  return (
    <>
      <TableRow>
        <TableCell colSpan={4} align="left" sx={{ border: 0, padding: 0 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <div className="col">
                  <h3 className="w-title" style={{ margin: 5, marginLeft: 20 }}>
                    PRUEBA USER
                  </h3>
                </div>
              </Grid>

              <Grid item xs={6}>
                <div className="col">
                  <h3 className="w-title" style={{ margin: 5, marginLeft: 0}}>
                    RUC: 89568457852
                  </h3>
                </div>
              </Grid>
            </Grid>
          </Box>
        </TableCell>
      </TableRow>

      <TableRow
        key={Math.random()}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell
          scope="row"
          style={{ minWidth: 200, margin: 5, marginLeft: 20 }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <div className="col">
                  <span className="w-title">Fecha de venta</span>
                  <span className="w-subtitle">
                    {moment(props?.FecReg).format("DD/MM/yyyy")}
                  </span>
                </div>
              </Grid>

              <Grid item xs={6}>
                <div className="col">
                  <span className="w-title">N de baterias vendidas</span>
                  <span className="w-subtitle">6</span>
                </div>
              </Grid>
              

            </Grid>
          </Box>

          <Box>
            <h3 className="w-title" style={{ margin: 5, display: 'flex', alignItems: 'center', gap: '0.5rem' }}>Baterías <KeyboardArrowDownIcon /> </h3>
            <Box>
              <TableContainer className="container-table-list">
                <Table className="w-table" aria-label="simple table" stickyHeader size="small">
                  <TableBody>
                    <TableRow key={0} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell><span className="w-title">303GFM</span></TableCell>
                      <TableCell><span className="w-title">LMS MTF 900</span></TableCell>
                      <TableCell><CheckCircleIcon color="success" /> </TableCell>
                      <TableCell><Button variant="contained" size="small" style={{ borderRadius: "20px" }}>VER</Button></TableCell>
                    </TableRow>

                    <TableRow key={1} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell><span className="w-title">303GFM</span></TableCell>
                      <TableCell><span className="w-title">LMS MTF 900</span></TableCell>
                      <TableCell><CancelIcon color="error" /> </TableCell>
                      <TableCell></TableCell>
                    </TableRow>

                    <TableRow key={2} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell><span className="w-title">303GFM</span></TableCell>
                      <TableCell><span className="w-title">LMS MTF 900</span></TableCell>
                      <TableCell><CheckCircleIcon color="success" /> </TableCell>
                      <TableCell><Button variant="contained" size="small" style={{ borderRadius: "20px" }}>VER</Button></TableCell>
                    </TableRow>
                  </TableBody>
                </Table>  
              </TableContainer>
            </Box>
          </Box>
        </TableCell>
      </TableRow>
      
      <Alert ref={AlertRef} />
    </>
  );
};
