import React from 'react';
import { StoreContext } from '../../store/StoreProvider';
import { Login } from '../Login/Login';
import './App.css';
import { Layout } from './Layout';
import { getVersionApp } from "../../store/sagas/Main";
import { UpdateVersionModal } from "../Home/UpdateVersionModal";

// import { types } from "../../store/Warranty";
import { types as typesAuth } from "../../store/Auth";
import AppConfig from "../../constants/AppConfig";

const {
  types: { seller, partner },
  // colors: { primary },
} = AppConfig;

function compareVersions(v1: string, v2: string): boolean {
  const v1Parts = v1.split('.').map(Number);
  const v2Parts = v2.split('.').map(Number);

  // Comparar cada parte secuencialmente
  for (let i = 0; i < Math.max(v1Parts.length, v2Parts.length); i++) {
    const v1Part = v1Parts[i] ?? 0;
    const v2Part = v2Parts[i] ?? 0;

    if (v1Part > v2Part) return true;  // v1 es mayor
    if (v1Part < v2Part) return false; // v2 es mayor
  }

  return false;
}

const App = React.memo(() => {

  const [store, dispatch] = React.useContext(StoreContext);
  const [openUpdate, setOpenUpdate] = React.useState(false);

  const prevUpdateVersionApp = React.useRef(store.updateVersionApp);

  React.useEffect(() => {
    console.log(`Gardi Internacional - ${process.env.REACT_APP_VERSION}`);
    // console.log('version', store?.updateVersionApp,prevUpdateVersionApp.current )
    if (store.updateVersionApp !== prevUpdateVersionApp.current) {
      prevUpdateVersionApp.current = store.updateVersionApp;

      getVersionApp(null, true).then((response) => {
        if (response.status === 200 || response.statusCode === 200) {
          // dispatch({ type: types.setUpdateVersionApp, payload: false });
          const appVersionActual = process.env.REACT_APP_VERSION ?? "1.0.0.0";
          const versionAppResponse = response.value[0];

          if (versionAppResponse && compareVersions(versionAppResponse, appVersionActual)) {
            setOpenUpdate(true);
          }
        }
      }).catch((error) => {
        // console.log(error)
      });
    }

  }, [store.updateVersionApp, dispatch, store?.userAppCorp]);

  React.useEffect(() => {
    const logout = () => {
      if (store?.user?.tipo === seller) {
        dispatch({ type: typesAuth.authLogoutRV });
      } else if (store?.user?.tipo === partner) {
        dispatch({ type: typesAuth.authLogoutSN });
      } else {
        dispatch({ type: typesAuth.authLogout });
      }
    };

    if (!!store && !!store?.user && !!store?.user?.auth) {
      const dateTimeToDay = new Date();

      if (store?.user?.dateTimeLogin) {
        const dateTimeLogin = new Date(store?.user?.dateTimeLogin);

        if (dateTimeToDay.getDate() !== dateTimeLogin.getDate() && dateTimeToDay.getHours() > 0 && store?.isLogoutTime !== true) {
          logout();
        }
      }

      if (!store?.user?.dateTimeLogin) {
        logout();
      }
    }
  })

  return (
    <div className="App">
      {!!store && !!store?.user && !!store?.user?.auth ? <Layout /> : <Login />}
      <UpdateVersionModal open={openUpdate} />
    </div>
  );
})

export default App;
