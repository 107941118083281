import * as React from 'react';
import Box from "@mui/material/Box";
import { StoreContext } from '../../store/StoreProvider';
// import { types } from '../../store/Dialog';
import { getI18N } from '../../i18n'
import { ClientCard } from '../App/ClientCard';
import { Button, MenuItem } from "@material-ui/core";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import InputBase from "@mui/material/InputBase";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import LogoutIcon from "@mui/icons-material/Logout";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { IconQr } from '../icons/IconQr';
import { IconSearch } from "../icons/IconSearch";
import Loading from "../Loading";
import { Scanner } from "./Scanner";
import AddIcon from '@mui/icons-material/Add';
import { IconTrash } from "../icons/IconTrash";
import { DisplaySelects } from './DisplaySelects';
import { Menu } from '@mui/material';
import { types } from '../../store/Clients';

import { MultiInput } from "../MultiInput";

import TextField from '@mui/material/TextField';


interface HomeManagementProps { }

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const HomeManagementComponent = (props: HomeManagementProps) => {

  const [store, dispatch] = React.useContext(StoreContext);

  const { Country } = store?.user;
  const currentLocale: string = Country || "PE";
  const i18n = getI18N({ currentLocale })

  const [managementType, setManagementType] = React.useState<number>(0);

  /*  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
     dispatch({ type: types.change_tab, payload: newValue });
   }; */
  const handleChange = (value?: number) => {
    // dispatch({ type: types.change_tab, payload: managementType });
    dispatch({ type: types.managementType, payload: value || managementType });
  };



  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (value?: number) => {
    if (value !== undefined) {
      setManagementType(value)
    }
    setAnchorEl(null);
  };

  return (
    <Box className="home-management-container">

      <Grid container columnSpacing={{ xs: 2, sm: 2, md: 2 }} style={{ justifyContent: "center", alignItems: "center" }}>
        <Grid item xs={6}>
          <Button
            variant={managementType === 1 ? "contained" : 'outlined'}
            className="btn-primary rounded-button"
            color={managementType === 1 ? "primary" : 'default'}
            style={{ borderRadius: "20px", fontWeight: "bold", maxWidth: "100%", minWidth: "50%" }}
            onClick={() => {
              setManagementType(1)
              handleChange(1)
            }}
            disabled={false}
          >
            Gestión de flota
          </Button>
        </Grid>

        <Grid item xs={6}>

          <Button
            variant={managementType === 2 || managementType === 3 ? "contained" : 'outlined'}
            className="btn-primary rounded-button"
            color={managementType === 2 || managementType === 3 ? "primary" : 'default'}
            style={{ borderRadius: "20px", fontWeight: "bold", maxWidth: "100%", minWidth: "50%" }}
            onClick={handleClick}
            disabled={false}
          >
            SOCIO CORPORATIVO <KeyboardArrowDownIcon />
          </Button>

          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => handleClose()} style={{ width: 200 }} sx={{ width: anchorEl?.offsetWidth }} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            PaperProps={{
              style: {
                width: '100%',
                minWidth: "100%",
                maxWidth: "100%",
              },
            }}>
            <MenuItem key={Math.random()} selected={managementType === 2} onClick={() => handleClose(2)}>Venta</MenuItem>
            <MenuItem key={Math.random()} selected={managementType === 3} onClick={() => handleClose(3)}>Taller</MenuItem>
          </Menu>
        </Grid>
      </Grid>

      {[2, 3].includes(managementType) && <Box marginBottom={"1rem"} style={{ marginTop: "1rem", padding: "4vh 2vw" }} sx={{ flexGrow: 1, padding: "0.8rem" }}>
        <Grid container spacing={1}>
          <Grid item xs={12} style={{ padding: "1rem", background: "#FFFFFF", borderRadius: "15px" }}>

            <Box style={{ padding: '1rem' }} >

              <Grid container spacing={1} style={{ backgroundColor: '#F1F1EF', borderRadius: '15px', padding: '0.5rem' }} >
                <Grid item xs={12}>
                  <TextField label={'Nombre'} fullWidth></TextField>
                </Grid>

                <Grid item xs={12}>
                  <TextField label={'DNI / RUC'} fullWidth></TextField>
                </Grid>

                <Grid item xs={12}>
                  <TextField label={'TELEF.'} fullWidth></TextField>
                </Grid>
              </Grid>

            </Box>

            {managementType === 2 && <Box>
              <div className="input-container">
                <Paper
                  component="form"
                  className="form-input-custom"
                  sx={{
                    p: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <InputBase
                    className="uppercase"
                    sx={{ ml: 1, flex: 1 }}
                    placeholder={i18n.BATTERY_CODE}
                    inputProps={{ "aria-label": `${i18n.BATTERY_CODE}` }}
                    // value={searchCode}
                    onChange={(event) => {
                      // setCode(event.target.value.toLocaleUpperCase())
                    }}
                    onSubmit={(e) => {
                      e.preventDefault();
                      // search("code");
                    }}
                  />
                  <IconButton
                    onClick={() => { }}
                    sx={{ p: "10px" }}
                    aria-label="Leer código QR"
                    disableFocusRipple={true}
                    disabled={false}
                  >
                    {/* <QrCodeScannerIcon /> */}
                    <IconQr />
                  </IconButton>
                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                  <IconButton
                    color="primary"
                    sx={{ p: "10px" }}
                    aria-label="Buscar código"
                    type="submit"
                    onClick={(e) => {
                      e.preventDefault();
                      // search("code");
                    }}
                  >
                    <Loading loading={false}>
                      {/* <SearchIcon /> */}
                      <IconSearch />
                    </Loading>
                  </IconButton>
                  {
                    store?.userAppCorp && !store.openCreateWarrantyRequest ? (<Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />) : <></>
                  }
                  {
                    store?.userAppCorp && !store.openCreateWarrantyRequest ? (
                      <IconButton
                        // onClick={addCodeBattery}
                        sx={{ p: "10px" }}
                        aria-label="Agregar Código"
                        disableFocusRipple={true}
                        style={{ color: "#000" }}
                      >
                        <Loading loading={false}>
                          <AddIcon />
                        </Loading>

                      </IconButton>
                    ) : <></>
                  }

                </Paper>
              </div>
              {/* {!!scan_qr && (
              <div className="scanner-container">
                <Scanner
                  callback={(res: any) => {
                    let code = res;
                    if (res.length > 6 && res.indexOf("=") !== -1) {
                      code = res.split("=")[1];
                    }

                    if (code !== '') {
                      // setScan(false);
                    }

                    // findById(code);
                  }}
                />
              </div>
            )} */}

              <Box sx={{ flexGrow: 1, padding: "0.8rem" }} className="container-body-action-corp">
                <Paper variant="outlined" style={{ borderColor: "#000000", borderRadius: "15px" }}>
                  <Box sx={{ flexGrow: 1 }} className="item-codigo" key={0}>
                    <Grid container spacing={2} columns={16} justifyContent="start" alignItems="center">
                      <Grid item xs={12}>
                        <h3 style={{ margin: "0" }}>CODIGO001</h3>
                      </Grid>
                      <Grid item xs={4}>
                        <IconButton
                          onClick={() => {
                            // handleRemoveCodeShipt(index)
                          }}
                          sx={{ p: "10px" }}
                          aria-label=""
                          disableFocusRipple={true}
                          style={{ color: "#000" }}
                          color="primary"
                        >
                          <IconTrash />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box sx={{ flexGrow: 1 }} className="item-codigo" key={1}>
                    <Grid container spacing={2} columns={16} justifyContent="start" alignItems="center">
                      <Grid item xs={12}>
                        <h3 style={{ margin: "0" }}>CODIGO002</h3>
                      </Grid>
                      <Grid item xs={4}>
                        <IconButton
                          onClick={() => {
                            // handleRemoveCodeShipt(index)
                          }}
                          sx={{ p: "10px" }}
                          aria-label=""
                          disableFocusRipple={true}
                          style={{ color: "#000" }}
                          color="primary"
                        >
                          <IconTrash />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box sx={{ flexGrow: 1 }} className="item-codigo" key={2}>
                    <Grid container spacing={2} columns={16} justifyContent="start" alignItems="center">
                      <Grid item xs={12}>
                        <h3 style={{ margin: "0" }}>CODIGO002</h3>
                      </Grid>
                      <Grid item xs={4}>
                        <IconButton
                          onClick={() => {
                            // handleRemoveCodeShipt(index)
                          }}
                          sx={{ p: "10px" }}
                          aria-label=""
                          disableFocusRipple={true}
                          style={{ color: "#000" }}
                          color="primary"
                        >
                          <IconTrash />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Box>
            </Box>}

            <Grid item xs={12} style={{ padding: "1rem" }}>
              <Button
                variant="contained"
                className="btn-primary rounded-button"
                color="primary"
                style={{ borderRadius: "20px", fontWeight: "bold", maxWidth: "100%", minWidth: "50%", marginBottom: '1rem' }}
                onClick={() => {
                  handleChange(managementType)
                }}
                disabled={false}
              >
                Guardar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box >}



    </Box >
  );
};

